<template>
  <div class="e-content">
    <div id="UnderstandingRouterJs">
      <h1>Routing</h1>
      <p>사이트의 페이지 간 경로를 설정하고 이동하는 방법에 대해 알 수 있습니다.
      </p>
      <b-card title="Understanding router.js">
        <b-card-text>Vue Router를 사용합니다. src 디렉토리의 router.js 파일에서 경로를 추가 및 제거할 수 있습니다.</b-card-text>
        <div class="card-code">
        <pre class="px-2 d-flex align-items-center"><code class="language-javascript">
          {{ codeRouter }}
          </code>
        </pre>
        </div>
        <b-card-text>위의 router.js 코드는 vue 및 vue-router를 가져와 앱에 등록합니다. main.js 파일에서 사용하기 위해 다음 코드를 내보냅니다.</b-card-text>
        <div class="card-code">
        <pre class="px-2 d-flex align-items-center"><code class="language-javascript">
          {{ codeRouterExport }}
          </code>
        </pre>
        </div>
      </b-card>

      <b-card id="UnderstandingRouterInstance" title="Understanding Router() Instance">
        <p><span class="font-weight-bolder">mode:</span> Router()에서 가장 먼저 알 수 있는 것은 모드 옵션이며 이 옵션은 기록으로 설정되어 있습니다. 기본값은 Hash Mode입니다. Hash Mode는 모든 URL을 hash(#)형태로 반환합니다. URL 해시를 사용하여 URL이 변경될 때 페이지가 다시 로드되지 않도록 전체 URL을 시뮬레이션합니다. 해시를 없애기 위해 히스토리 모드를 사용했습니다. history.pushState API를 활용하여 페이지를 새로고침하지 않고도 URL 탐색이 가능합니다. 라우터에서 히스토리 모드를 사용하여 아름다운 URL을 만들 수 있습니다.
        </p>
        <p><span class="font-weight-bolder">base:</span> app의 모든 URL에 기본적으로 적용됩니다. 기본값은 '/'입니다.
        </p>
        <p><span class="font-weight-bolder">scrollBehavior():</span> 새로운 경로로 이동할 때 맨 위로 스크롤을 할 수 있게 해줍니다. return 문 return {x:0, y:0}은 새 경로를 방문할 때 페이지를 맨 위로 스크롤하도록 만듭니다.
        </p>
      </b-card>
      <b-card id="SettingRoutes" title="Setting Routes">
        <div class="card-code">
        <pre class="px-2 d-flex align-items-center"><code class="language-javascript">
          {{ codeSettingRoutes }}
          </code>
        </pre>
        </div>
        <p><span class="font-weight-bold">path:</span> 레코드의 경로를 지정합니다. 다른 레코드의 자식이 아닌 경우 '/'로 시작해야 합니다. '*'는 route에 설정된 어떤 경로와도 매칭되지 않을 경우를 의미합니다.
        </p>
        <p><span class="font-weight-bold">name:</span> 레코드의 고유한 이름입니다.
        </p>
        <p><span class="font-weight-bold">component:</span> 라우팅 하고자 하는 컴포넌트를 import 합니다.
        </p>
        <p><span class="font-weight-bold">meta:</span> 추가적인 데이터를 첨부하며, layout을 지정할 수 있습니다.
        </p>
        <p><span class="font-weight-bold">redirect:</span> URL 경로가 잘못되었을 때, 미리 설정해 놓은 URL로 이동시킵니다.
        </p>
      </b-card>

        <b-card id="RouterPush" title="$router.push">
          <b-card-text>Router Push를 사용해서 라우트 이동 시 파라미터(데이터)를 전달합니다.</b-card-text>
          <b-card-text>Router Push를 사용한 이유는 </b-card-text>
          <b-card-text>1. 'https://scan.beatsomeone.com/block/blockNum'의 형태로 URL을 넘겨주기 위해서 사용했습니다.</b-card-text>
          <b-card-text>2. List 페이지에서 상세 페이지 이동 시 클릭한 해당 데이터를 넘겨주기 위해서 사용했습니다.</b-card-text>
          <h5>📁 views/Home.vue</h5>
          <div class="card-code">
          <pre class="px-2 d-flex align-items-center"><code
              class="language-javascript">{{ codeRouterpushList }}</code></pre>
          </div>
          <h5>📁 views/BlockDetails.vue</h5>
          <div class="card-code">
          <pre class="px-2 d-flex align-items-center"><code
              class="language-javascript">{{ codeRouterpushDetails }}</code></pre>
          </div>
          <h5>📁 router/index.js</h5>
          <div class="card-code">
          <pre class="px-2 d-flex align-items-center"><code
              class="language-javascript">{{ codeRouterpushRouter }}</code></pre>
          </div>
        </b-card>

      <b-card id="NavigationMenu" title="Navigation Menu">
        <b-card-text>네비게이션 바의 메뉴 항목을 관리합니다. 항목을 유연하게 관리하기 위해 섹션별로 다른 파일을 만들 수 있습니다. 네비게이션의 메뉴 항목을 업데이트 하려면 <code>src/navigation/index.js</code>를 업데이트합니다.</b-card-text>
        <p><span class="font-weight-bolder">title:</span> 네비게이션 메뉴로 표시되는 내용입니다.
        </p>
        <p><span class="font-weight-bolder">route:</span> 라우터로 설정한 경로를 적습니다.
        </p>
      </b-card>
    </div>
  </div>
</template>

<script>
import {BCard, BCardText} from 'bootstrap-vue'
import {codeRouter, codeRouterExport, codeSettingRoutes, codeRouterpushList, codeRouterpushDetails, codeRouterpushRouter} from './code'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import Prism from 'vue-prism-component'


export default {
  components: {
    BCard,
    BCardText,
    Prism,
  },
  data() {
    return {
      rightNavMenu: ['UnderstandingRouterJs','UnderstandingRouterInstance', 'SettingRoutes', 'RouterPush', 'NavigationMenu'],
      codeRouter,
      codeRouterExport,
      codeSettingRoutes,
      codeRouterpushList,
      codeRouterpushDetails,
      codeRouterpushRouter
    }
  },
  created() {
    this.$store.commit('verticalMenu/UPDATE_RIGHT_NAV_MENU', this.rightNavMenu)
  },
}
</script>

